<script>
import {
  provide,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
  onUnmounted,
  watchEffect,
  h,
} from 'vue'
import { defaultConfigs } from './defaults'
import {
  debounce,
  throttle,
  getSlidesVNodes,
  getCurrentSlideIndex,
  getMaxSlideIndex,
  getMinSlideIndex,
  getSlidesToScroll,
} from './utils'
import {useStore} from 'vuex'
export default {
  name: 'CarouselHomeBanner',
  props: {
    // count of items to showed per view
    itemsToShow: {
      default: defaultConfigs.itemsToShow,
      type: Number,
    },
    // count of items to be scrolled
    itemsToScroll: {
      default: defaultConfigs.itemsToScroll,
      type: Number,
    },
    // control infinite scrolling mode
    wrapAround: {
      default: defaultConfigs.wrapAround,
      type: Boolean,
    },
    // control snap position alignment
    snapAlign: {
      default: defaultConfigs.snapAlign,
      validator(value) {
        // The value must match one of these strings
        return ['start', 'end', 'center', 'center-even', 'center-odd'].includes(
          value,
        )
      },
    },
    // sliding transition time in ms
    transition: {
      default: defaultConfigs.transition,
      type: Number,
    },
    // an object to store breakpoints
    breakpoints: {
      default: defaultConfigs.breakpoints,
      type: Object,
    },
    // time to auto advance slides in ms
    autoplay: {
      default: defaultConfigs.autoplay,
      type: Number,
    },
    // pause autoplay when mouse hover over the carousel
    pauseAutoplayOnHover: {
      default: defaultConfigs.pauseAutoplayOnHover,
      type: Boolean,
    },
    // slide number number of initial slide
    modelValue: {
      default: undefined,
      type: Number,
    },
    // toggle mouse dragging.
    mouseDrag: {
      default: defaultConfigs.mouseDrag,
      type: Boolean,
    },
    // toggle mouse dragging.
    touchDrag: {
      default: defaultConfigs.touchDrag,
      type: Boolean,
    },
    // an object to pass all settings
    settings: {
      default() {
        return {}
      },
      type: Object,
    },
    slideIndexInit: {
      type: Number,
      default: 0
    }
  },
  setup(props, { slots, emit, expose }) {
    const root = ref(null)
    const slides = ref([])
    const slidesBuffer = ref([])
    const slideWidth = ref(0)
    const slidesCount = ref(1)
    const autoplayTimer = ref(null)
    const transitionTimer = ref(null)
    const store = useStore()

    let breakpoints = ref({})

    // generate carousel configs
    let defaultConfig = { ...defaultConfigs }
    // current config
    const config = reactive({ ...defaultConfigs })

    // slides
    // const currentSlideIndex = ref(config.modelValue ?? 0)
    const currentSlideIndex = ref(props.slideIndexInit)
    const prevSlideIndex = ref(0)
    const middleSlideIndex = ref(0)
    const maxSlideIndex = ref(0)
    const minSlideIndex = ref(0)

    provide('config', config)
    provide('slidesBuffer', slidesBuffer)
    provide('slidesCount', slidesCount)
    provide('currentSlide', currentSlideIndex)
    provide('maxSlide', maxSlideIndex)
    provide('minSlide', minSlideIndex)

    /**
     * Configs
     */
    function initDefaultConfigs() {
      // generate carousel configs
      const mergedConfigs = {
        ...props,
        ...props.settings,
      }

      // Set breakpoints
      breakpoints = ref({ ...mergedConfigs.breakpoints })

      // remove extra values
      defaultConfig = {
        ...mergedConfigs,
        settings: undefined,
        breakpoints: undefined,
      }

      bindConfigs(defaultConfig)
    }
    function updateBreakpointsConfigs() {
      const breakpointsArray = Object.keys(breakpoints.value)
        .map((key) => Number(key))
        .sort((a, b) => +b - +a)
      let newConfig = { ...defaultConfig }

      breakpointsArray.some((breakpoint) => {
        const isMatched = window.matchMedia(
          `(min-width: ${breakpoint}px)`,
        ).matches
        if (isMatched) {
          newConfig = {
            ...newConfig,
            ...breakpoints.value[breakpoint],
          }
          return true
        }
        return false
      })

      bindConfigs(newConfig)
    }

    function bindConfigs(newConfig) {
      for (let key in newConfig) {
        // @ts-ignore
        config[key] = newConfig[key]
      }
    }
    const handleWindowResize = debounce(() => {
      if (breakpoints.value) {
        updateBreakpointsConfigs()
        updateSlidesData()
      }
      updateSlideWidth()
    }, 16)

    /**
     * Setup functions
     */

    function updateSlideWidth() {
      if (!root.value) return
      const rect = root.value.getBoundingClientRect()
      slideWidth.value = rect.width / config.itemsToShow
    }

    function updateSlidesData() {
      slidesCount.value = slides.value.length
      if (slidesCount.value <= 0) return

      middleSlideIndex.value = Math.ceil((slidesCount.value - 1) / 2)
      maxSlideIndex.value = getMaxSlideIndex(config, slidesCount.value)
      minSlideIndex.value = getMinSlideIndex(config)
      // console.log(' [typing] updateSlidesData before',currentSlideIndex.value)
      currentSlideIndex.value = getCurrentSlideIndex(
        config,
        currentSlideIndex.value,
        maxSlideIndex.value,
        minSlideIndex.value,
      )
    }

    function updateSlidesBuffer() {
      const slidesArray = [...Array(slidesCount.value).keys()]
      const shouldShiftSlides =
        config.wrapAround && config.itemsToShow + 1 <= slidesCount.value

      if (shouldShiftSlides) {
        const buffer =
          config.itemsToShow !== 1
            ? Math.round((slidesCount.value - config.itemsToShow) / 2)
            : 0
        let shifts = buffer - currentSlideIndex.value

        if (config.snapAlign === 'end') {
          shifts += Math.floor(config.itemsToShow - 1)
        } else if (
          config.snapAlign === 'center' ||
          config.snapAlign === 'center-odd'
        ) {
          shifts++
        }

        // Check shifting directions
        if (shifts < 0) {
          for (let i = shifts; i < 0; i++) {
            slidesArray.push(Number(slidesArray.shift()))
          }
        } else {
          for (let i = 0; i < shifts; i++) {
            slidesArray.unshift(Number(slidesArray.pop()))
          }
        }
      }
      slidesBuffer.value = slidesArray
    }

    onMounted(() => {
      if (breakpoints.value) {
        updateBreakpointsConfigs()
        updateSlidesData()
      }
      setTimeout(() => {
        updateSlideWidth()
      }, 500)

      if (config.autoplay && config.autoplay > 0) {
        initializeAutoplay()
      }

      window.addEventListener('resize', handleWindowResize, { passive: true })
    })

    onUnmounted(() => {
      if (transitionTimer.value) {
        clearTimeout(transitionTimer.value)
      }
      resetAutoplayTimer(false)
    })

    /**
     * Carousel Event listeners
     */
    let isTouch = false
    const startPosition = { x: 0, y: 0 }
    const endPosition = { x: 0, y: 0 }
    const dragged = reactive({ x: 0, y: 0 })
    const isDragging = ref(false)
    const isHover = ref(false)

    const handleMouseEnter = () => {
      isHover.value = true
    }
    const handleMouseLeave = () => {
      isHover.value = false
    }
    const handleDrag = throttle((event) => {
      if (!isTouch) event.preventDefault()

      endPosition.x = isTouch ? event.touches[0].clientX : event.clientX
      endPosition.y = isTouch ? event.touches[0].clientY : event.clientY
      const deltaX = endPosition.x - startPosition.x
      const deltaY = endPosition.y - startPosition.y

      dragged.y = deltaY
      dragged.x = deltaX
    }, 16)

    function handleDragStart(event) {
      isTouch = event.type === 'touchstart'

      if (!isTouch) event.preventDefault()
      if ((!isTouch && event.button !== 0) || isSliding.value) {
        return
      }

      isDragging.value = true
      startPosition.x = isTouch ? event.touches[0].clientX : event.clientX
      startPosition.y = isTouch ? event.touches[0].clientY : event.clientY

      document.addEventListener(isTouch ? 'touchmove' : 'mousemove', handleDrag)
      document.addEventListener(isTouch ? 'touchend' : 'mouseup', handleDragEnd)
    }

    function handleDragEnd() {
      isDragging.value = false

      const tolerance = Math.sign(dragged.x) * 0.4
      const draggedSlides = Math.round(dragged.x / slideWidth.value + tolerance)

      let newSlide = getCurrentSlideIndex(
        config,
        currentSlideIndex.value - draggedSlides || 0,
        maxSlideIndex.value,
        minSlideIndex.value,
      )
      slideTo(newSlide)

      dragged.x = 0
      dragged.y = 0

      document.removeEventListener(
        isTouch ? 'touchmove' : 'mousemove',
        handleDrag,
      )
      document.removeEventListener(
        isTouch ? 'touchend' : 'mouseup',
        handleDragEnd,
      )
    }

    /**
     * Autoplay
     */
    function initializeAutoplay() {
      autoplayTimer.value = setInterval(() => {
        if (config.pauseAutoplayOnHover && isHover.value) {
          return
        }
        if(props.autoplay !== 0) next()
      }, config.autoplay)
    }

    function resetAutoplayTimer(restart = true) {
      if (!autoplayTimer.value) {
        return
      }

      clearInterval(autoplayTimer.value)
      if (restart) {
        initializeAutoplay()
      }
    }

    /**
     * Navigation function
     */
    const isSliding = ref(false)
    function slideTo(slideIndex, mute = false) {
      resetAutoplayTimer()

      if (currentSlideIndex.value === slideIndex || isSliding.value) {
        return
      }

      // Wrap slide index
      const lastSlideIndex = slidesCount.value - 1
      if (slideIndex > lastSlideIndex) {
        return slideTo(slideIndex - slidesCount.value)
      }
      if (slideIndex < 0) {
        return slideTo(slideIndex + slidesCount.value)
      }

      isSliding.value = true
      prevSlideIndex.value = currentSlideIndex.value
      currentSlideIndex.value = slideIndex

      if (!mute) {
        emit('update:modelValue', currentSlideIndex.value)
      }
      transitionTimer.value = setTimeout(() => {
        if (config.wrapAround) updateSlidesBuffer()
        isSliding.value = false
      }, config.transition)
    }

    function next() {
      let nextSlide = currentSlideIndex.value + config.itemsToScroll
      if (!config.wrapAround) {
        nextSlide = Math.min(nextSlide, maxSlideIndex.value)
      }
      store.commit('generalStore/setCarouselNextSlide', nextSlide)
      slideTo(nextSlide)
    }

    function prev() {
      let prevSlide = currentSlideIndex.value - config.itemsToScroll
      if (!config.wrapAround) {
        prevSlide = Math.max(prevSlide, minSlideIndex.value)
      }
      store.commit('generalStore/setCarouselPreSlide', prevSlide)
      slideTo(prevSlide)
    }
    const nav = { slideTo, next, prev }
    provide('nav', nav)

    /**
     * Track style
     */
    const slidesToScroll = computed(() =>
      getSlidesToScroll({
        slidesBuffer: slidesBuffer.value,
        itemsToShow: config.itemsToShow,
        snapAlign: config.snapAlign,
        wrapAround: Boolean(config.wrapAround),
        currentSlide: currentSlideIndex.value,
        slidesCount: slidesCount.value,
      }),
    )
    provide('slidesToScroll', slidesToScroll)

    const trackStyle = computed(() => {
      // const xScroll = dragged.x - slidesToScroll.value * slideWidth.value
      return {
        // transform: `translateX(${xScroll}px)`,
        transition: `${isSliding.value ? config.transition : 0}ms`,
      }
    })

    function initCarousel() {
      initDefaultConfigs()
    }

    function restartCarousel() {
      initDefaultConfigs()
      updateBreakpointsConfigs()
      updateSlidesData()
      updateSlidesBuffer()
      updateSlideWidth()
    }

    function updateCarousel() {
      updateSlidesData()
      updateSlidesBuffer()
    }

    // Update the carousel on props change
    watch(props, restartCarousel)

    // Init carousel
    initCarousel()

    watchEffect(() => {
      // Handel when slides added/removed
      const needToUpdate = slidesCount.value !== slides.value.length
      const currentSlideUpdated =
        props.modelValue !== undefined &&
        currentSlideIndex.value !== props.modelValue
      // console.log(currentSlideIndex.value, ' [typing] watchEffect ', props.modelValue)
      if (currentSlideUpdated) {
        slideTo(Number(props.modelValue), true)
      }

      if (needToUpdate) {
        updateCarousel()
      }
    })

    const data = {
      config,
      slidesBuffer,
      slidesCount,
      slideWidth,
      currentSlide: currentSlideIndex,
      maxSlide: maxSlideIndex,
      minSlide: minSlideIndex,
      middleSlide: middleSlideIndex,
    }
    expose({
      updateBreakpointsConfigs,
      updateSlidesData,
      updateSlideWidth,
      updateSlidesBuffer,
      initCarousel,
      restartCarousel,
      updateCarousel,
      slideTo,
      next,
      prev,
      nav,
      data,
    })

    const slotSlides = slots.default || slots.slides
    const slotAddons = slots.addons
    const slotCounter = slots.counter
    const slotsProps = reactive(data)

    const slidesElements = getSlidesVNodes(slotSlides?.(slotsProps))
    const addonsElements = slotAddons?.(slotsProps) || []
    slides.value = slidesElements
    // // Bind slide order
    slidesElements.forEach((el, index) => (el.props.index = index))

    // return {
    //   slotSlides,
    //   slotAddons,
    //   trackStyle,
    //   handleMouseEnter,
    //   handleMouseLeave,
    //   handleDragStart,
    //   getSlidesVNodes,
    // }

    return () => {
      const trackEl = h(
        'ol',
        {
          class: 'carousel__track',
          style: trackStyle.value,
          onMousedown: config.mouseDrag ? handleDragStart : null,
          onTouchstart: config.touchDrag ? handleDragStart : null,
        },
        slidesElements,
      )
      const counterElement = h('div', {}, slotCounter())

      const viewPortElement = h('div', { class: 'carousel__viewport' }, [
        counterElement,
        trackEl,
      ])

      return h(
        'section',
        {
          ref: root,
          class: 'carousel_home_banner',
          'aria-label': 'Gallery',
          onMouseenter: handleMouseEnter,
          onMouseleave: handleMouseLeave,
        },
        [viewPortElement, addonsElements],
      )
    }
  },
}
</script>
<style lang="scss" scoped>
.carousel_home_banner {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  .carousel__viewport {
    overflow: hidden;
    height: 100%;
  }
}
.carousel_home_banner * {
  box-sizing: border-box;
}
.carousel__track {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
}

</style>
